import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import "../leporello/Leporello.scss";
import {
  combineVenues,
  combineVenuesMobile,
  venues,
} from "../../i18n/route-utils";
import { zeroPad, filterActiveShows } from "../../utilities";
import {
  GET_ACTIVE_VENUES,
  GET_TIME_FRAME,
  GET_SPECIAL_EVENTS,
} from "../../queries";
import LepoRow from "../leporello/LepoRow/LepoRow";
import { useResizer } from "../../customHooks";

//TODO: Add aria-labelledby to venue items and specify date and venue as labels

const SpecialEvents = () => {
  let intl = useIntl();
  const isMobile = useResizer();
  const [dayRows, setDayRows] = useState();

  const { data: months } = useQuery(GET_TIME_FRAME);
  const { data: activeVenues } = useQuery(GET_ACTIVE_VENUES);

  const endDateString = moment()
    .set("year", months.timeFrame.end.year)
    .set("month", months.timeFrame.end.month - 1)
    .endOf("month")
    .add(1, "day")
    .format("YYYYMMDD");

  // TODO this gets a day too early and could double events
  const { data, loading } = useQuery(GET_SPECIAL_EVENTS, {
    variables: {
      from: endDateString,
    },
    onCompleted: (data) => {
      if (data.events && data.events.nodes) {
        mapDays(filterActiveShows(data.events.nodes));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const mapEvents = (events) =>
    new Promise((resolve) => {
      let newDays = [];
      const allVenues = isMobile ? combineVenuesMobile : combineVenues;

      for (let event of events) {
        let dayObj = newDays.find(
          (element) =>
            element.dateString === String(event.eventDetails.filterableDate)
        );

        const dateExists = !!dayObj;

        dayObj = dateExists
          ? dayObj
          : {
              dateString: String(event.eventDetails.filterableDate),
              date: moment.utc(event.eventDetails.startTime),
              rows: 1,
              even: false,
              venues: allVenues.map((v) => ({ id: v.id, events: [] })),
            };

        let venueObj = allVenues.find((x) =>
          x.venues.includes(Number(event.eventDetails.stageId))
        );

        let venueArr = dayObj.venues.find((v) => v.id === venueObj.id);
        venueArr.events.push(event);

        if (!dateExists) newDays.push(dayObj);
      }

      newDays.sort((a, b) => Number(a.dateString) - Number(b.dateString));
      resolve(newDays);
    });

  const mapDays = (data) => {
    mapEvents(data).then((newDays) => {
      let totalRows = 1;
      for (let day of newDays) {
        for (let venue of day.venues) {
          day.rows =
            venue.events.length > day.rows ? venue.events.length : day.rows;
        }

        let setEven = !!(totalRows % 2 === 0);
        day.even = setEven;
        totalRows = totalRows + day.rows;
      }

      setDayRows(newDays);
    });
  };

  if (loading) {
    return null;
  }

  return dayRows && dayRows.length > 0 ? (
    <div className="leporello SpecialEvents">
      <main
        role="main"
        className="programmeList"
        aria-label={intl.formatMessage({ id: "aria.programme" })}
      >
        <h2 className="month">
          <FormattedMessage id="events.presale" defaultMessage="Vorverkauf" />
        </h2>
        {dayRows.map((day, i) => (
          <LepoRow
            date={day.date}
            dateString={day.dateString}
            key={day.dateString}
            data={dayRows ? dayRows[i] : {}}
            selectedVenues={activeVenues.selectedVenues}
          />
        ))}
      </main>
    </div>
  ) : null;
};

export default SpecialEvents;
