import moment from "moment";

const now = moment();
export const nowFormatted = String(now.format("YMMDDHHmmss"));
export const todayDateString = String(now.format("yyyyMMDD"));
export const newFormatDateString = "20231101";

export const isNewFormat = (compareDate) => {
  return !!moment(compareDate).isSameOrAfter(moment(newFormatDateString));
};

export const fetchData = async (url) => {
  return fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
  })
    .then((response) => response.json())
    .then((venues) => venues)
    .catch((err) => console.error(err));
};

// export const fetchAll = (fetchMoreFunction, data) => {
//   let newData;

//   function fetchAgain() {
//     fetchMoreFunction({
//       variables: { after: data.pageInfo.endCursor },
//       updateQuery: (previousResult, { fetchMoreResult }) => {
//         let newData = [...data, ...fetchMoreResult];
//         return newData;
//       },
//     });
//   }
// };

export const zeroPad = (value, wantedLength) => {
  let result = String(value);

  while (result.length < wantedLength) {
    result = `${"0"}${result}`;
  }
  return result;
};

export const sortEventsByDate = (a, b) => {
  if (a.eventDetails.filterableDate < b.eventDetails.filterableDate) {
    return -1;
  }
  if (a.eventDetails.filterableDate > b.eventDetails.filterableDate) {
    return 1;
  }
  return 0;
};

export const sortEventsByDateReverse = (a, b) => {
  if (a.eventDetails.filterableDate < b.eventDetails.filterableDate) {
    return 1;
  }
  if (a.eventDetails.filterableDate > b.eventDetails.filterableDate) {
    return -1;
  }
  return 0;
};

export const sortShowsByDate = (a, b) => {
  if (
    (a.productionInfo.premiereEvent ||
      a.productionInfo.plannedPremiere.premiereDate) &&
    (b.productionInfo.premiereEvent ||
      b.productionInfo.plannedPremiere.premiereDate)
  ) {
    const startA = moment(
      a.productionInfo.premiereEvent
        ? a.productionInfo.premiereEvent.eventDetails.startTime
        : a.productionInfo.plannedPremiere.premiereDate
    );
    const startB = moment(
      b.productionInfo.premiereEvent
        ? b.productionInfo.premiereEvent.eventDetails.startTime
        : b.productionInfo.plannedPremiere.premiereDate
    );

    if (startA.isSameOrBefore(startB)) {
      return -1;
    }

    if (startA.isSameOrAfter(startB)) {
      return 1;
    }
  } else if (
    (a.productionInfo.premiereEvent ||
      a.productionInfo.plannedPremiere.premiereDate) &&
    !(
      b.productionInfo.premiereEvent ||
      b.productionInfo.plannedPremiere.premiereDate
    )
  ) {
    return -1;
  } else if (
    !(
      a.productionInfo.premiereEvent ||
      a.productionInfo.plannedPremiere.premiereDate
    ) &&
    (b.productionInfo.premiereEvent ||
      b.productionInfo.plannedPremiere.premiereDate)
  ) {
    return 1;
  }

  return 0;
};

export const sortShowsByDateReverse = (a, b) => {
  if (
    (a.productionInfo.premiereEvent ||
      a.productionInfo.plannedPremiere.premiereDate) &&
    (b.productionInfo.premiereEvent ||
      b.productionInfo.plannedPremiere.premiereDate)
  ) {
    const startA = moment(
      a.productionInfo.premiereEvent
        ? a.productionInfo.premiereEvent.eventDetails.startTime
        : a.productionInfo.plannedPremiere.premiereDate
    );
    const startB = moment(
      b.productionInfo.premiereEvent
        ? b.productionInfo.premiereEvent.eventDetails.startTime
        : b.productionInfo.plannedPremiere.premiereDate
    );

    if (startA.isSameOrBefore(startB)) {
      return 1;
    }

    if (startA.isSameOrAfter(startB)) {
      return -1;
    }
  } else if (
    (a.productionInfo.premiereEvent ||
      a.productionInfo.plannedPremiere.premiereDate) &&
    !(
      b.productionInfo.premiereEvent ||
      b.productionInfo.plannedPremiere.premiereDate
    )
  ) {
    return -1;
  } else if (
    !(
      a.productionInfo.premiereEvent ||
      a.productionInfo.plannedPremiere.premiereDate
    ) &&
    (b.productionInfo.premiereEvent ||
      b.productionInfo.plannedPremiere.premiereDate)
  ) {
    return 1;
  }
  return 0;
};

export const showWillPremiere = (show) => {
  if (
    show.productionInfo.premiereEvent ||
    show.productionInfo.plannedPremiere.premiereDate
  ) {
    const date = show.productionInfo.premiereEvent
      ? show.productionInfo.premiereEvent.eventDetails.startTime
      : show.productionInfo.plannedPremiere.premiereDate;

    return moment(date).isAfter(now);
  }

  return false;
};

export const productionIsInSeason = (show, season) => {
  const seasonStart = moment(season.spielzeitInfo.spielzeitStart);
  const seasonEnd = moment(season.spielzeitInfo.spielzeitEnd);

  if (
    show.productionInfo.premiereEvent ||
    show.productionInfo.plannedPremiere.premiereDate
  ) {
    const date = show.productionInfo.premiereEvent
      ? show.productionInfo.premiereEvent.eventDetails.startTime
      : show.productionInfo.plannedPremiere.premiereDate;

    return moment(date).isBetween(seasonStart, seasonEnd);
  }

  return false;
};

export const mapPerson = (array) => {
  const newData = [];
  for (let person of array) {
    if (person.length) {
      let obj = {};
      let arr = person.split(":");

      obj.id = arr[0];
      obj.name = arr[1];

      newData.push(obj);
    }
  }
  return newData;
};

export const sortPeople = (a, b) => {
  if (Number(a.sort) < Number(b.sort)) {
    return -1;
  } else if (Number(a.sort) > Number(b.sort)) {
    return 1;
  }

  if (Number(a.position) < Number(b.position)) {
    return -1;
  } else if (Number(a.position) > Number(b.position)) {
    return 1;
  }

  if (a.last_name !== b.last_name) {
    return a.last_name.localeCompare(b.last_name);
  }

  return a.first_name.localeCompare(b.first_name);
};

export const eventIsInPast = (event) => {
  return moment(event.eventDetails.startTime).isBefore(now);
};

export const eventCancelledThea = (event) => {
  const cancelledTypes = ["canceledExtern", "canceledrequest", "optional"];

  return !!(
    event.eventDetails.planningStatus &&
    cancelledTypes.includes(event.eventDetails.planningStatus)
  );
};

export const filterActiveShows = (linkArray) => {
  const filtered = linkArray.filter((link) => {
    return showActive(link);
  });
  return filtered;
};

export const filterShowsForCrew = (show) => {
  return (
    show.productionInfo.showInRepertoire ||
    show.productionInfo.showInArchive ||
    show.productionDetails.isSeries ||
    show.slug.includes("p14")
  );
};

export const showActive = (item) => {
  if (process.env.REACT_APP_ENV === "DEV") return true;

  if (
    item &&
    item.showOnline &&
    item.showOnline.isOnline &&
    item.showOnline.isOnline.includes("active")
  ) {
    return true;
  } else if (
    item &&
    item.showOnline &&
    item.showOnline.activateOn &&
    item.showOnline.activateOn <= nowFormatted
  ) {
    return true;
  }
  return false;
};

const umlautMap = {
  "\u00dc": "UE",
  "\u00c4": "AE",
  "\u00d6": "OE",
  "\u00fc": "ue",
  "\u00e4": "ae",
  "\u00f6": "oe",
  "\u00df": "ss",
};

export function replaceUmlaute(str) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(
      new RegExp("[" + Object.keys(umlautMap).join("|") + "]", "g"),
      (a) => umlautMap[a]
    );
}

export const customInfoMapping = {
  Premiere: "premiere",
  "Berliner Premiere": "berlin",
  "Berliner Filmpremiere": "berlin-film-opening",
  Uraufführung: "first",
  Eröffnung: "opening",
  Festivaleröffnung: "festival-opening",
  Wiederaufnahme: "back",
  "VS zum letzten Mal": "last",
  "VS zum vorläufig letzten Mal": "last-for-now",
  "VS zum letzten Mal in der VB": "last-vb",
  Gastspiel: "on-tour",
  "Volksbühnen-Premiere": "vb-premiere",
};

export const replaceBacktick = (str) => {
  return str.replace("\u0060", "’");
};

export const specialLepoSections = {
  20240710: "festiwalla",
  // 20240714: "summer-break",
};

export const isFestiwalla = (date) => {
  return !!(Number(date) > 20240709 && Number(date) < 20240715);
};
